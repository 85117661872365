import React from "react"
import { Link } from "gatsby"
import AuthenticatedLayout from "../components/layouts/authenticatedLayout"
import newCourseService from "../services/course_service";
import SectionPreviewComponent from "../components/course-browser/section-preview";
import AuthenticatedFullscreenLayout from "../components/layouts/authenticatedFullscreenLayout";
const BonfirePage = () => (
    <AuthenticatedFullscreenLayout title="Bonfire Scene" section={newCourseService.sections.bonfire} />
)

export default BonfirePage
